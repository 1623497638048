import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Gender } from '../../shared/filter/store-filter/store-filter.model';

@Injectable({
  providedIn: 'root',
})
export class APIService {
  constructor(private readonly http: HttpClient) { }

  getFilters(gender: Gender): Observable<any> {
    return this.http.get(`/assets/mocks/filters-${gender}.json`);
  }
}
