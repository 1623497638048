import { Component, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { APIService } from 'src/app/common/services/api.service';
import { Gender } from './store-filter.model';
import { StoreFilterService } from './store-filter.service';

@Component({
  selector: 'app-store-filter',
  templateUrl: './store-filter.component.html',
  styleUrls: ['./store-filter.component.css'],
})
export class StoreFilterComponent {
  @Output() selectedFilters: EventEmitter<any> = new EventEmitter();
  subscriptions: Subscription[] = [];

  vm$ = this.storeFilterService.vm$;

  constructor(
    private readonly apiService: APIService,
    private readonly storeFilterService: StoreFilterService
  ) {
    this.subscriptions.push(
      this.apiService.getFilters(Gender.KIDS).subscribe((response) => {
        this.storeFilterService.setCategories(response.categories);
        this.storeFilterService.setSizes(response.sizes);
        this.storeFilterService.setPriceRange(response.priceRange);
        this.storeFilterService.setColors(response.colors);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  selectFilters(vm) {
    this.selectedFilters.emit({
      selectedCategories: vm.selectedCategories,
      selectedSizes: vm.selectedSizes,
      selectedColors: vm.selectedColors,
      selectedPriceMin: vm.selectedPriceMin,
      selectedPriceMax: vm.selectedPriceMax,
    });
  }
}
