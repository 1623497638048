<app-header></app-header>
<header class="bg-indigo-100 px-10">
  <div class="container mx-auto">
    <div class="py-4 px-4">
      <a class="text-3xl font-bold text-gray-900 cursor-pointer flex items-center"
        (click)="showFiltersClick()">
        Filter
        <svg *ngIf="!showFilters" xmlns="http://www.w3.org/2000/svg"
          class="h-10 w-10 pt-2" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd" />
        </svg>
        <svg *ngIf="showFilters" xmlns="http://www.w3.org/2000/svg"
          class="h-10 w-10 pt-2" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd"
            d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
            clip-rule="evenodd" />
        </svg>

      </a>
    </div>
    <div [ngClass]=" {'block
    animate-fade-in-down' : showFilters, ' hidden' : !showFilters}">
      <app-store-filter (selectedFilters)="selectFilters($event)">
      </app-store-filter>
    </div>
  </div>
</header>
<pre class="bg-gray-200 p-4">
  {{selectedFilters | json}}
</pre>