import { NgModule } from '@angular/core';

import { StoreFilterComponent } from './store-filter/store-filter.component';
import { FilterTitleComponent } from './components/filter-title/filter-title.component';
import { FilterCheckComponent } from './components/filter-check/filter-check.component';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  imports: [BrowserModule],
  declarations: [StoreFilterComponent, FilterTitleComponent, FilterCheckComponent],
  exports: [StoreFilterComponent, FilterTitleComponent, FilterCheckComponent]
})
export class FilterModule { }
