import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-filter-title',
  templateUrl: './filter-title.component.html',
  styleUrls: ['./filter-title.component.css']
})
export class FilterTitleComponent {
  @Input() title: string;
}
