import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  showFilters = false;
  selectedFilters: any;

  showFiltersClick() {
    this.showFilters = !this.showFilters;
  }

  selectFilters(filters) {
    this.selectedFilters = filters;
    this.showFilters = false;
  }
}
