export interface FilterState {
  gender: Gender;
  categories: string[];
  sizes: number[];
  colors: Color[];
  priceRange: Range[];
  selectedCategories: string[];
  selectedSizes: number[];
  selectedColors: string[];
  selectedPriceMin: number;
  selectedPriceMax: number;
}

export interface FilterResponse {
  categories: string[];
  sizes: number[];
  colors: Color[];
  priceRange: Range[];
}

export interface Color {
  hexCode: string;
  title: string;
}

export interface Range {
  title: string;
  min: number;
  max: number;
}

export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
  KIDS = 'kids',
}
