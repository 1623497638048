<nav class="bg-indigo-400 text-white px-6">
  <div class="container mx-auto flex justify-between">
  <div class="flex items-center">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
      viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
    </svg>
    <p class="text-xl font-bold ml-2">
      ShopHere
    </p>
  </div>
  <div class="flex items-center">
    <a
      class="py-6 px-4 font-bold cursor-pointer hover:bg-indigo-800 hover:text-white">Home</a>
    <a
      class="py-6 px-4 font-bold cursor-pointer hover:bg-indigo-800 hover:text-white">Catalog</a>
    <a
      class="py-6 px-4 font-bold cursor-pointer hover:bg-indigo-800 hover:text-white">Blog</a>
  </div>
</div>
</nav>