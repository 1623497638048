import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-filter-check',
  templateUrl: './filter-check.component.html',
  styleUrls: ['./filter-check.component.css']
})
export class FilterCheckComponent {
  @Input() title: string = '';
  @Input() checked: boolean = false;
  @Input() value: any;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
}
