<ng-container *ngIf="vm$ | async as vm">
  <div class="flex flex-row border-t-2">
    <div class="w-1/5 mx-4">
      <app-filter-title title="Gender"></app-filter-title>
      <div class="flex items-center pb-4">
        <input class="text-indigo-500" value="kids" type="radio" name="gender"
          [checked]="vm.gender == 'kids'"
          (click)="storeFilterService.selectGender('kids')" />
        <p class="ml-2 text-sm">KIDS</p>
      </div>
      <div class="flex items-center pb-4">
        <input class="text-indigo-500" value="female" type="radio" name="gender"
          [checked]="vm.gender == 'female'"
          (click)="storeFilterService.selectGender('female')" />
        <p class="ml-2 text-sm">FEMALE</p>
      </div>
      <div class="flex items-center pb-4">
        <input class="text-indigo-500" value="male" type="radio" name="gender"
          [checked]="vm.gender == 'male'"
          (click)="storeFilterService.selectGender('male')" />
        <p class="ml-2 text-sm">MALE</p>
      </div>
    </div>
    <div class="w-1/5 mx-4 flex flex-wrap">
      <app-filter-title title="Categories" class="w-full"></app-filter-title>
      <app-filter-check *ngFor="let category of vm.categories" class=" w-1/2"
        [value]="category" [title]="category"
        [checked]="vm.selectedCategories.includes(category)"
        (onChange)="storeFilterService.selectCategory($event.target.value,$event.target.checked)">
      </app-filter-check>
    </div>
    <div class="w-1/5 mx-4 flex flex-wrap">
      <app-filter-title title="Sizes" class="w-full"></app-filter-title>
      <app-filter-check *ngFor="let size of vm.sizes" class="w-1/2"
        [value]="size.toString()" [title]="size"
        [checked]="vm.selectedSizes.includes(size.toString())"
        (onChange)="storeFilterService.selectSize($event.target.value,$event.target.checked)">
      </app-filter-check>
    </div>
    <div class="w-1/5 mx-4 flex flex-wrap">
      <app-filter-title title="Colors" class="w-full"></app-filter-title>
      <app-filter-check *ngFor="let color of vm.colors" class="w-1/2"
        [value]="color.hexCode" [title]="color.title"
        [checked]="vm.selectedColors.includes(color.hexCode)"
        (onChange)="storeFilterService.selectColor($event.target.value, $event.target.checked)">
      </app-filter-check>
    </div>
    <div class="w-1/5 mx-4 flex flex-wrap">
      <app-filter-title title="Price Range" class="w-full"></app-filter-title>
      <app-filter-check *ngFor="let range of vm.priceRange" class="w-full pb-1"
        [value]="range" [title]="range.title"
        (onChange)="storeFilterService.selectPriceRange($event.target.checked, range)">
      </app-filter-check>
    </div>
  </div>
  <div class="flex justify-center py-4">
    <button class="px-4 py-2 rounded bg-indigo-500 text-white text-lg"
      (click)="selectFilters(vm)">
      Select
    </button>
  </div>
</ng-container>