import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { EMPTY, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { APIService } from 'src/app/common/services/api.service';
import {
  Color,
  FilterResponse,
  FilterState,
  Gender,
  Range,
} from './store-filter.model';

@Injectable({
  providedIn: 'root',
})
export class StoreFilterService extends ComponentStore<FilterState> {
  constructor(private readonly apiService: APIService) {
    super({
      gender: Gender.KIDS,
      priceRange: [],
      categories: [],
      sizes: [],
      colors: [],
      selectedCategories: [],
      selectedSizes: [],
      selectedColors: [],
      selectedPriceMin: 0,
      selectedPriceMax: 0,
    });
  }

  // *********** state selectors *********** //
  private readonly gender$: Observable<Gender> = this.select(
    (state) => state.gender
  );
  private readonly categories$: Observable<string[]> = this.select(
    (state) => state.categories
  );
  private readonly sizes$: Observable<number[]> = this.select(
    (state) => state.sizes
  );
  private readonly colors$: Observable<Color[]> = this.select(
    (state) => state.colors
  );
  private readonly priceRange$: Observable<Range[]> = this.select(
    (state) => state.priceRange
  );
  private readonly selectedCategories$: Observable<string[]> = this.select(
    (state) => state.selectedCategories
  );
  private readonly selectedSizes$: Observable<number[]> = this.select(
    (state) => state.selectedSizes
  );
  private readonly selectedColors$: Observable<string[]> = this.select(
    (state) => state.selectedColors
  );
  private readonly selectedPriceMin$: Observable<number> = this.select(
    (state) => state.selectedPriceMin
  );
  private readonly selectedPriceMax$: Observable<number> = this.select(
    (state) => state.selectedPriceMax
  );

  // *********** view model for component *********** //
  readonly vm$ = this.select(
    this.gender$,
    this.categories$,
    this.selectedCategories$,
    this.sizes$,
    this.selectedSizes$,
    this.colors$,
    this.selectedColors$,
    this.priceRange$,
    this.selectedPriceMin$,
    this.selectedPriceMax$,
    (
      gender,
      categories,
      selectedCategories,
      sizes,
      selectedSizes,
      colors,
      selectedColors,
      priceRange,
      selectedPriceMin,
      selectedPriceMax
    ) => ({
      gender,
      categories,
      selectedCategories,
      sizes,
      selectedSizes,
      colors,
      selectedColors,
      priceRange,
      selectedPriceMin,
      selectedPriceMax,
    })
  );

  // *********** state updaters *********** //
  readonly setCategories = this.updater(
    (state: FilterState, categories: string[]) => {
      return {
        ...state,
        categories,
      };
    }
  );

  readonly setSizes = this.updater((state: FilterState, sizes: number[]) => {
    return {
      ...state,
      sizes,
    };
  });

  readonly setColors = this.updater((state: FilterState, colors: Color[]) => {
    return {
      ...state,
      colors,
    };
  });

  readonly setPriceRange = this.updater(
    (state: FilterState, priceRange: Range[]) => {
      return {
        ...state,
        priceRange,
      };
    }
  );

  readonly selectPriceRange = (checked: boolean, priceRange: Range) => {
    this.patchState((state) => {
      let minValue = state.selectedPriceMin;
      let maxValue = state.selectedPriceMax;
      if (checked) {
        if (minValue === 0 || priceRange.min < minValue) {
          minValue = priceRange.min;
        }
        if (priceRange.max > maxValue) {
          maxValue = priceRange.max;
        }
      } else {
        // TODO: fix it
      }
      return { ...state, selectedPriceMin: minValue, selectedPriceMax: maxValue };
    });

  };

  readonly selectCategory = (category: string, checked: boolean) => {
    this.patchState((state) => {
      if (checked) {
        return { selectedCategories: [...state.selectedCategories, category] };
      } else {
        return {
          selectedCategories: state.selectedCategories.filter(
            (c) => c !== category
          ),
        };
      }
    });
  };

  readonly selectSize = (size: number, checked: boolean) => {
    this.patchState((state) => {
      if (checked) {
        return { selectedSizes: [...state.selectedSizes, size] };
      } else {
        return { selectedSizes: state.selectedSizes.filter((s) => s !== size) };
      }
    });
  };

  readonly selectColor = (color: string, checked: boolean) => {
    this.patchState((state) => {
      if (checked) {
        return { selectedColors: [...state.selectedColors, color] };
      } else {
        return {
          selectedColors: state.selectedColors.filter((c) => c !== color),
        };
      }
    });
  };

  // state effects
  readonly selectGender = this.effect((gender$: Observable<Gender>) => {
    return gender$.pipe(
      concatMap((gender: any) =>
        this.apiService.getFilters(gender).pipe(
          tapResponse(
            (response: FilterResponse) => {
              this.patchState({
                colors: response.colors,
                categories: response.categories,
                priceRange: response.priceRange,
                sizes: response.sizes,
                selectedCategories: [],
                selectedColors: [],
                selectedSizes: [],
                selectedPriceMin: 0,
                selectedPriceMax: 1000,
              });
            },
            (error) => EMPTY
          )
        )
      )
    );
  });
}
